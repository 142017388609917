import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.opaque.white,
    color: theme.palette.secondary.main,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down(550)]: {
      paddingTop: theme.spacing(6),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  formWrapper: {
    position: 'relative',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  titleContainer: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    maxWidth: '380px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
      width: '734px',
      height: '150px',
    },
    [theme.breakpoints.down(550)]: {
      width: 'inherit',
      height: 'inherit',
    },
  },
  titleText: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'inherit',
      marginBottom: 'inherit',
      textAlign: 'center',
    },
    [theme.breakpoints.down(550)]: {
      marginBottom: theme.spacing(5),
    },
  },
  formBgFill: {
    flexGrow: '1',
    backgroundColor: theme.palette.offwhite.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iframeWrapper: {
    paddingLeft: theme.spacing(7),
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.offwhite.main,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down(550)]: {
      width: '100%',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
      paddingBottom: 0,
      borderRadius: '8px 8px 0 0',
    },
  },
  h5: {
    color: theme.palette.secondary.light,
    marginBottom: '0.5rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    color: 'black',
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    fontSize: '12px',
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.25rem',
      textAlign: 'center',
    },
  },
  socialSection: {
    flexGrow: '1',
    backgroundColor: theme.palette.offwhite.main,
    paddingLeft: theme.spacing(7),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.down(550)]: {
      borderRadius: '0 0 8px 8px',
    },
  },
  socialIcons: {
    display: 'flex',
    width: '100%',
    marginTop: '1.25rem',
    justifyContent: 'flex-start',
    '&>a': {
      paddingRight: '10px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    '& svg': {
      transform: 'scale(1.1)',
      color: theme.palette.primary.dark,
    },
    '& svg:hover': {
      transition: '250ms',
      color: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
}))

export default useStyles
