import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { IconTikTokLogo, XIcon } from '@components/atoms/icons'
import FacebookIcon from '@material-ui/icons/Facebook'
import useStyles from './marketing-form-embed.styles'

const MarketingFormEmbed = (props) => {
  const {
    headline,
    digiohFormId,
    socialHeadline,
    socialDescription,
    socialCollection,
  } = props

  const styles = useStyles()
  const { asPath, isReady } = useRouter() || {}
  const [resetKey, setResetKey] = useState('')

  const icons = {
    instagram: InstagramIcon,
    youtube: YouTubeIcon,
    linkedin: LinkedInIcon,
    x: XIcon,
    facebook: FacebookIcon,
    tiktok: IconTikTokLogo,
  }

  useEffect(() => {
    const apiReadyDelay = setInterval(() => {
      if (window?.DIGIOH_API?.isReady()) {
        window.DIGIOH_API.enableReloadOnUrlChange()
        clearInterval(apiReadyDelay)
      }
    }, 10)
    return () => {
      clearInterval(apiReadyDelay)
    }
  }, [])

  useEffect(() => {
    if (isReady) {
      setResetKey(asPath)
    }
  }, [asPath, isReady])

  return digiohFormId ? (
    <div key={resetKey} className={styles.root}>
      <Container className={styles.formWrapper}>
        <div className={styles.titleContainer}>
          <Typography variant="h4" className={styles.titleText}>
            {headline}
          </Typography>
        </div>
        <div className={styles.iframeWrapper}>
          <div id={digiohFormId} />
        </div>
        {socialCollection?.items ? (
          <Box className={styles.socialSection}>
            <Typography
              variant="h5"
              variantMapping={{ h5: 'h3' }}
              className={styles.h5}
            >
              {socialHeadline}
            </Typography>
            <Typography variant="body2" className={styles.description}>
              {socialDescription}
            </Typography>
            <Box className={styles.socialIcons}>
              {
                // eslint-disable-next-line no-shadow
                socialCollection.items?.map(({ headline, link }) => {
                  const iconName = headline.toLowerCase()
                  const Icon = icons[iconName] || InstagramIcon
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link}
                      title={iconName}
                      key={headline}
                    >
                      <Icon titleAccess={headline} />
                    </a>
                  )
                })
              }
            </Box>
          </Box>
        ) : (
          <div className={styles.formBgFill} />
        )}
      </Container>
    </div>
  ) : null
}

MarketingFormEmbed.propTypes = {
  headline: PropTypes.string,
  digiohFormId: PropTypes.string,
  socialHeadline: PropTypes.string,
  socialDescription: PropTypes.string,
  socialCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.string,
        headline: PropTypes.string,
      }),
    ),
  }),
}

MarketingFormEmbed.defaultProps = {}

export default MarketingFormEmbed
